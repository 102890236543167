import $ from 'jquery';
var imagesLoaded = require('imagesloaded');

import 'jquery-mousewheel';

imagesLoaded.makeJQueryPlugin( $ );


var vw;
var vh;
var colorchange = false;
var scrollTop;

$(document).ready(function() {

    Initialize();

    //// LOADING HOME PAGE ////


    if( $(location).attr('pathname') == "/fr" || $(location).attr('pathname') == "/en"){

        var domain = document.location.protocol+"//"+document.location.hostname+document.location.pathname;
        var url = domain+"/home.json";

        var target = $('main');

        $.get(url, function (data) {
            $(target).html(data);
            resizewindow();
            Initialize();
        });

    }



    //// AJAX LOADING PAGES ////

    $("body").on('click', '.ajax-link', function (e) {

        if($(this).hasClass("sub-sub-menu-link")){
            $(".sub-sub-menu-link").removeClass("td-red");
            $(this).addClass("td-red");
        }


        if($(this).hasClass("menu-link")){

            $(".menu-link").removeClass("td-undl");
            $(this).addClass("td-undl");
        }



        $('.navbar-collapse').removeClass("show");
        $('.navbar-toggler').addClass("collapsed");


        e.preventDefault();

        var pageTitle = $(this).html();
        var element = $(this);
        var target = $('main');
        var subsubpage = false;


        if(element.hasClass("sub-menu-link")){

            $("header").addClass("bkg-pink");
            $('main').addClass("bkg-white");
            colorchange = true
        }

        if(element.hasClass("change-color") || element.hasClass("menu-link") || element.hasClass("return-home")) {
            $("header").removeClass("bkg-pink");
            $('main').removeClass("bkg-white");
            colorchange = false;
        }

        if(element.hasClass("change-color-white")){
            $("header").addClass("bkg-pink");
            $('main').addClass("bkg-white");
        }

        if(element.hasClass("return-home")){
            $('.ajax-link').removeClass("td-undl");
            resizewindow();
        }

        if(element.hasClass("save-scroll")){
            scrollTop = $('main').scrollTop();

        }

        if(element.hasClass("return-to-scroll")){
            scroll = true;

        }else{
            scroll = false;
        }



        /*if($(this).hasClass("lang")){

            site_url = location.origin;
            page_url = location.pathname;
            lang = $(this).attr('href');

            $(".language").removeClass("active td-undl");
            $(this).parent().addClass("active td-undl");

            if( lang == "/en") {
                new_lang = page_url.replace("/fr", lang);
            }

            if( lang == "/fr") {
                new_lang = page_url.replace("/en", lang);
            }

            url = new_lang+".json";
            history_url =  new_lang;
        }else{
            history_url =  element.data("page");
            url = element.data("page")+".json";
        }*/

        var history_url =  element.data("page");
        url = element.data("page")+".json";

        if (!$(this).hasClass("no-remove")){
            $(target).html("");
        }


        $.get(url, function (data) {
            $("main").animate({ scrollTop: 0 },0);
            $(target).html(data);
            if(scroll === true){
                if(scrollTop === undefined){
                    $("main").animate({scrollTop: 0}, 0);
                }else {
                    $("main").animate({scrollTop: scrollTop}, 0);
                }
            }
            $('.home-text').hide();
            history.pushState(null, null, history_url);
            Initialize();
        });
    });

    var is_hash = false;
    $(window).on('hashchange', function() {
        is_hash = true;
    });



    $(window).on('popstate', function () {
        if (is_hash) {
            event.preventDefault();
            // reset
            var is_hashed = false;
            return false;
        }else {
            var target = $('main');
            var hostname = window.location.origin;
            var previous = location.pathname;
            var url = hostname + previous + '.json';


            if (url == hostname + '/fr.json') {
                url = hostname + "/home" + '.json';
            }

            $.get(url, function (data) {
                $("main").animate({ scrollTop: 0 },0);
                $(target).html(data);

                if($(".content").data("back") == "white"){
                    $('main').addClass("bkg-white");
                    $('header').addClass("bkg-pink");
                }else if ($(".content").data("back") == "pink"){
                    $('main').removeClass("bkg-white");
                    $('header').removeClass("bkg-pink");
                }





                if(scrollTop === undefined){
                    $("main").animate({scrollTop: 0}, 0);
                }else {
                    $("main").animate({scrollTop: scrollTop}, 0);
                }
                $('.home-text').hide();
                if (url == hostname + "/home" + '.json') {
                }
                Initialize();
            });
        }


    });


});



function Initialize() {
    resizewindow();

    $('.home-gallery').imagesLoaded(function () {
    $('.gallery-item').each(function () {
            width = $(this).width() - 2;
            var legend = $(this).find(".gallery-item-legend");
            legend.css("width",width);
        });
    });

    var pageTitle = $('.content').data("title");
    if(pageTitle == ""){
        $('title').text("Cirva");
    }else{
        $('title').text("Cirva • " + pageTitle);
    }






    var parent_page = $('.content').data("parent");

    $('.nav-page-title').html("<p style='display: inline;' class='td-undl'>"+parent_page+"</p>");

    if(parent_page == null) {
        $('.nav-page-title').html("<p style='display: inline;' class='td-undl'></p>");

    }


    var menu_pink;

    $('main a').prop('target', '_blank');

    $('.navbar-toggler').click(function () {

        if($(this).hasClass("collapsed")){

            if($("header").hasClass("bkg-pink")){
                menu_pink = true;
            }

            if(menu_pink == true){
                $('header').removeClass();
            }
        }

        if(!$(this).hasClass("collapsed")){
            if(menu_pink == true){
                setTimeout(function() {
                    $('header').addClass("bkg-pink");
                },600);
            }
        }


    });




    $( window ).resize(function() {
        resizewindow();
    });



    $(".home-gallery").mousewheel(function (event, delta) {
        if(vw > 768) {
            this.scrollLeft -= (delta * event.deltaFactor);
            event.preventDefault();
        }
    });



    $('.gallery-item').hover(function () {
        if(vw > 768) {
            $(this).find('.gallery-item-legend').css('display', "flex");
        }

    }, function () {
        if(vw > 768) {
            $(this).find('.gallery-item-legend').css('display', "none");
        }
    });

    $('.home-text .close-btn, .home-text').click(function (e) {
        e.preventDefault();
        $(".home-text").fadeOut(200);
        resizewindow();
    }).children().click(function (e) {
        return false;
    });

    $('.close-btn').click(function (e) {
        e.preventDefault();
        $(this).parent().fadeOut(200);
        resizewindow();
    });


    $('.submenu .filter').click(function (e) {

        e.preventDefault();

        $('.filter').removeClass("td-undl");
        $(this).addClass("td-undl");

        filter = "."+$(this).data('filter');

        $('.grid-item, .filtered').removeClass("show").addClass("hide");
        $(filter).removeClass("hide").addClass("show");


    });

    $('.toggle-expand').click(function (e) {

        e.preventDefault();
        if ($(this).hasClass("only-one")){
            if($(this).hasClass("expanded")){
                $(this).removeClass("expanded");
            }else{
                $('.only-one').removeClass("expanded");
                $(this).addClass("expanded");
            }
        }else{
            $(this).toggleClass("expanded");
        }

    });

    $('.options').on('change', function() {
        $('.options').not(this).prop('checked', false);
        $('#sib-form').attr("action",$(this).data("action"));
        $('#sib-form').attr("data-type",$(this).data("type"));
    });

    $(".archives a").each(function () {

        $(this).attr("target","_blank");

    });

    $('.append-cover').hover(function () {

      var image_url;
      image_url = $(this).data("cover");
       var orientation = $(this).data("orientation");
        $('.artist-cover-image').html("");
        $('.artist-cover-image').removeClass("portrait landscape square");
        $('.artist-cover-image').append("<img src='"+image_url+"'>");
        $('.artist-cover-image').addClass(orientation);

    }, function () {

    });


    $( ".grid-item.autrespublications").last().addClass("nb-b-sm");


    $('.sub-menu-link').click(function () {
        $('.spe-sub-page').removeClass("td-undl");
    });


    // RADIO


        if($('.page').data("template") == "radio") {

            /// ITS WORKING LIKE THIS

            var url = window.location.href;
            var id = "#"+url.split('#!')[1];

            target = $(id).find(".radio-collapse");
            var headerHeight = $('header').height();

            // Open target
            setTimeout(function () {

                var mainScrollTop = $("main").scrollTop();


                $(id).find(".toggle-expand").addClass("expanded");
                $('main').animate({
                    scrollTop: $(id).offset().top - headerHeight
                }, 500, function(){


                    $('main').animate({
                        scrollTop: $(id).offset().top - headerHeight
                    }, 500);
                    target.collapse();
                });
            }, 500);
        }


    // RADIO LISTENER

        $('.toggle-play').click(function(){

            if($(id).hasClass("selected")){
              var onTop = true;
            }else{
                var onTop = false;
            }


            id = "#"+this.closest(".radio-item").getAttribute("id");
            $('.radio-item').removeClass("playing");
            $(id).addClass("playing");

            var playBtn = this;
            var radioItem = this.closest(".radio-item");
            headerHeight = $('header').height();
            var IsInTop = Math.round($(id).offset().top);


            var mainScrollTop = $("main").scrollTop();


            id = "#"+this.closest(".radio-item").getAttribute("id");

            $(".timeline").unbind('click');

            var clickedAudio = "#"+radioItem.querySelector("audio").getAttribute("id");


            var url = window.location.href;

            var searchInUrl = "#!"+url.split('#!')[1];
            var  appendToUrl = this.closest(".radio-item").getAttribute("id");


            window.history.replaceState( {} , searchInUrl, "#!"+appendToUrl );

            playBtn.classList.add("td-undl-white");

            var sounds = $('audio:not('+clickedAudio+')');
            for(i=0; i<sounds.length; i++){

                var OthersRadioItems = sounds[i].closest('.radio-item');
                sounds[i].pause();
                sounds[i].currentTime = 0;

                var length = OthersRadioItems.querySelector(".time .current").dataset.length;

                OthersRadioItems.querySelector(".toggle-expand").classList.remove("expanded");
                OthersRadioItems.querySelector(".time .current").textContent = length;
                OthersRadioItems.querySelector(".toggle-play").textContent = playBtn.dataset.play;
                OthersRadioItems.querySelector(".controls").style.backgroundColor = "";
                OthersRadioItems.querySelector(".toggle-play").style.color = "";
                OthersRadioItems.querySelector(".timeline .progress").style.width = "0%";
                OthersRadioItems.querySelector(".toggle-play").classList.remove("td-undl-white");
                $(OthersRadioItems).find(".collapse").collapse("hide");



            }


            if (radioItem.querySelector(".toggle-play").textContent == "Pause"){

                setTimeout(function () {
                    mainScrollTop = $("main").scrollTop();

                    $('main').animate({
                            scrollTop: $(id).offset().top - headerHeight + mainScrollTop
                        }, 500, function () {

                        }
                    );

                }, 900);

            }else{
                setTimeout(function () {
                    mainScrollTop = $("main").scrollTop();

                    $('main').animate({
                            scrollTop: $(id).offset().top - headerHeight + mainScrollTop
                        }, 500, function () {


                        mainScrollTop = $("main").scrollTop();

                        $('main').animate({
                            scrollTop: $(id).offset().top - headerHeight + mainScrollTop
                        }, 500);

                            $(radioItem).find(".collapse").collapse("show");
                            $(id).find(".toggle-expand").addClass("expanded");
                            $(id).addClass("selected")
                        }
                    );

                }, 900);
            }







        var audioPlayer = radioItem.querySelector(".audio-player");
            var audio = radioItem.querySelector("audio");
            var progressBar = audioPlayer.querySelector(".progress");





        if (audio.paused) {
        playBtn.classList.remove("play");
        playBtn.classList.add("pause");
        playBtn.textContent = playBtn.dataset.pause;
        audio.play();
        playBtn.closest(".controls").style.backgroundColor = "black";
        playBtn.closest(".controls .toggle-play").style.color = "white";

    } else {
        playBtn.removeAttribute("data-toggle");
        playBtn.classList.remove("pause");
        playBtn.classList.add("play");
        playBtn.textContent = playBtn.dataset.play;
        audio.pause();
            playBtn.closest(".controls").style.backgroundColor = "black";
            playBtn.closest(".controls .toggle-play").style.color = "white";
    }


            var timeline = audioPlayer.querySelector(".timeline");

            $(timeline).click(function (e) {
                var timelineWidth = window.getComputedStyle(timeline).width;
                var timeToSeek = e.offsetX / parseInt(timelineWidth) * audio.duration;
                audio.currentTime = timeToSeek;
            });

            var myVar = setInterval(myTimer, 0);

            function myTimer() {
                progressBar.style.width = audio.currentTime / audio.duration * 100 + "%";
                radioItem.querySelector(".time .current").textContent = getTimeCodeFromNum(
                    audio.currentTime)
            }

            function getTimeCodeFromNum(num) {
                var seconds = parseInt(num);
                var minutes = parseInt(seconds / 60);
                seconds -= minutes * 60;
                var hours = parseInt(minutes / 60);
                minutes -= hours * 60;

                if (hours === 0) return `${minutes}’${String(seconds % 60).padStart(2, 0)}’’`;
                return `${String(hours).padStart(2, 0)}h${minutes}’${String(
                    seconds % 60
                ).padStart(2, 0)}’’`;
            }

});

        // HANDLE FILTERS IN HORS LES MURS

        $('.submenu.filters-menu a').click(function (e){
          e.preventDefault();
          $('.submenu.filters-menu a').removeClass('td-undl');
          $(this).addClass('td-undl');

          var filter =  $(this).data('target');
          var filterClass = '.'+filter;

          tags = ['.all'];
          filterByTags(tags);
          $('.tag').removeClass('active');
          $('.tag[data-target="all"]').addClass('active');

          $('.event-content-grid').removeClass('show').filter(filterClass).addClass('show');

        });

        // HANDLE TAGS IN HORS LES MURS

        var tags = [];

        $('.tag').click(function (){
          $(this).toggleClass('active');
          if($(this).data('target') == 'all'){
            $('.tag').removeClass('active');
            $(this).addClass('active');
            tags = ['.all'];
            filterByTags(tags);
          }else{
            $('.tag[data-target="all"]').removeClass('active');
            tags = [];
            $('.tag.active').each(function (){
              var Class = " ."+$(this).data('target');
              tags.push(Class);
            });
            filterByTags(tags);
          }
        });


        function filterByTags(tags){
          tags = tags;
          if(tags.length === 0){
            tags = ['.all'];
            $('.tag[data-target="all"]').addClass('active');
          }
          console.log(tags);
          $('.grid-item').removeClass('show');
          $('.grid-item').filter(tags.toString()).addClass('show');
          }

}



function resizewindow() {
    vw = $(window).width();
    vh = $(window).height();
    var page_width = $('.page').width();

    $('.gallery-item').each(function () {

        var width = $(this).width() - 2;
        $('.home-gallery').imagesLoaded(function () {

        });
        var legend = $(this).find(".gallery-item-legend");
        legend.css("width",width);

    });

    var stickyTopX = $('.submenu.filters-menu').outerHeight();
    $('.submenu.tags-menu').css('--sticky-top', stickyTopX+"px");

}
